$bblue: #457b9d;
$bgreen: #45ab7a;
$bred: #f68963;
$lgray: #e0e0e0;
$dgray: #828282;
$bradius: 8px;

#editor-window {
    .level-checkboxes {
        margin-bottom: 15px;
    }

    #cavea2 {
        #seating-map {
           border: 0px;
            border-top: 2px;
            border-left: 2px;
            border-style: solid;
            border-color: $dgray;

        }
        div.ant-typography, .ant-typography p {
            margin: 0px;
        }

        .elementHover {
            &:hover {
                .elementHoverToggle {
                    visibility: visible;
                }
            }   
            .elementHoverToggle {
                visibility: hidden;
            }

        }

        .cr-level {
            
            .cr-section {
                transition: all 0.2s;
                padding: 0px;
                margin: 0px;
                &.active {
                    background: #0000000d;
                    padding: 10px;
                    border-radius: $bradius; 
                    transition: all 0.2s;

                    > .cr-section-label {
                        font-size: 1.2em;
                    }

                    .addBtn {
                        visibility: visible;
                        height: auto;
                        margin-bottom: 10px;
                        transition: all 0.2s;

                    }
                    .cr-row {
                        background: #0000000d;
                        padding: 10px;
                        border-radius: $bradius;
                        margin: 10px 0px;

                        .cr-row-seat-add-btn {
                        display: flex;
                        }

                        .cr-row-label {
                            visibility: visible;
                            height: auto;
                        }
                    }
                }

                > .cr-section-label {
                    text-align: center;
                    font-weight: 500;
                    cursor: pointer;
                    background: #0000000d;
                    border-radius: $bradius;
                    position: relative;
                    // min-width: 200px;
                }

                .addBtn {
                    text-align: center;
                    margin-bottom: 0px;
                    visibility: hidden;
                    height: 0px;
                }

                .cr-row {
                    padding: 0px;
                    margin: 0px;

                    .cr-row-label {
                        text-align: center;
                        margin-bottom: 10px;
                        visibility: hidden;
                        height: 0px;
                    }

                    .cr-row-seat-add-btn {
                        align-items: center;
                        justify-content: center;
                        padding: 8px;
                        cursor: pointer;
                        background: #0000001a;
                        margin-left: 5px;
                        border-radius: 50%;
                        display: none;
                    }

                    > .seats {
                        display: flex;
                        .cr-seat {
                            width: 30px;
                            height: 30px;
                            background: $bblue;
                            border-radius: $bradius;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border: 2px solid $bblue;
                            color: rgb(238, 238, 238);
                            &:not(:first-of-type) {
                                margin-left: 7px;
                            }
                            &.invisible {
                                border: 2px solid $dgray;
                                background: transparent;
                            }
                            &.permablock {
                                background: $dgray;
                                border: 2px solid $dgray;
                            }
                            &.writeable {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }

        }

        .element svg {
            background: #ff00000a;
            border-radius: 8px;
        }

    }
    
    
}