.explorer.root {
    // position: absolute;
    // z-index: 999;
    // background: white;
    // width: 400px;
    // padding: 20px;
    // border-radius: 8px;
    // border: 1px solid #e6e6e6;
    // box-shadow: 0px 0px 8px 2px #00000030;
    // top: 30px;
    // left: 80px;
    margin: -25px;
    margin-top: 20px;

    .title {
        width: 100%;
        cursor: move;
        margin-Bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .close {
            cursor: pointer;
            margin-bottom: 10px;
        }
    }

    .key-value {
        margin-bottom: 10px;
        display: flex;
    }

    .ant-collapse-content-box {
        padding-right: 0px !important;
    }
    .ant-collapse > .ant-collapse-item:last-child,
    .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
        border-radius: 0 0 2px 2px;
    }

    span.anticon.anticon-right.ant-collapse-arrow {
        top: 8px !important;
    }

    .ant-collapse-header {
        padding-right: 0px !important;
        padding-top: 2px !important;
        padding-bottom: 2px !important;
    }

}