@import '~antd/dist/antd.css';
@import './components/Cavea2/cavea2.scss';
@import './components/Explorer/explorer.scss';


.trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
}

.trigger:hover {
    color: #1890ff;
}

.site-layout .site-layout-background {
    background: #fff;
}

.logo {
    float: left;
    color: #ffffff7a;
    font-weight: bold;
    font-size: 18px;
    margin-right: 20px;
}

.cursor-pointer {
    cursor: pointer;
}

#editor-window {
    // &.shown {
    //     transform: translate(0px, 0px);
    // }
    // transform: translate(0px, 100%);
    // transition: .2s all ease-out;
    width: 100vw;
    height: 100vh;
    background: white;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1000;
}
#cavea2 {
    margin-left: 250px;
    background: #f0f2f5;
    height: 100vh;
    position: relativ;
    background-image: url('https://firebasestorage.googleapis.com/v0/b/churchreserve-71a2f.appspot.com/o/sample%2Fgrid_panel.png?alt=media&token=9b58766d-3a42-4d5b-99cc-9f4bd00897c0');
    z-index: 1;
    background-repeat: repeat;
    display: flex;
}
.details-form {
    position: absolute;
    left: 360px;
    top: 10px;
    width: 310px;
    z-index: 9999;
    box-shadow: 2px 4px 8px 0px #00000024;
}